
import { useProposalEnquiryTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import { ProposalEnquiryCriteriaPanel } from "./ProposalEnquiryCriteriaPanel/ProposalEnquiryCriteriaPanel";
// import { ProposalEnquiryCriteriaPanel } from "./ProposalEnquiryCriteriaPanel/ProposalEnquiryCriteriaPanel";


export const ProposalEnquiryRightPanel = () => {
    const [proposalEnquiryState] = useProposalEnquiryTracked();
    const isFilterSearchCriteria = proposalEnquiryState.isFilterSearchCriteria;
    const isShowRightCriteriaPanel = proposalEnquiryState.isShowRightCriteriaPanel;

    return <div className='im-charge-data-search-right-panel'>
        {/* {isShowRightCriteriaPanel && isFilterSearchCriteria && <><div className='im-charge-data-search-right-panel-title'>SEARCH CRITERIA</div><ProposalEnquiryCriteriaPanel /></>} */}
        {isShowRightCriteriaPanel && isFilterSearchCriteria && <><div className='im-charge-data-search-right-panel-title'>SEARCH CRITERIA</div><ProposalEnquiryCriteriaPanel /></>}
    </div>;
};
