import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ProposalEnquiryProvider } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { ProposalEnquiryComp } from "presentation/view/section/ProposalEnquiry/ProposalEnquiryComp";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const ProposalEnquiryContainer = () => <ANAInfoWrapper permission={Permission.LOGIN}>
    <ProposalEnquiryProvider>
        <GridStyles/>
        {/* <h1>ProposalEnquiryContainer</h1> */}
        <ProposalEnquiryComp/>
    </ProposalEnquiryProvider>
</ANAInfoWrapper>;