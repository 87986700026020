
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { ProposalEnquiryRepoImpl } from "domain/repository/Proposal/ProposalEnquiryRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TariffTypeRepoImpl } from "domain/repository/TariffCode/TariffTypeRepoImpl";
import { useProposalEnquiryTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import { ProposalEnquiryVM } from "presentation/viewModel/ProposalEnquiry/ProposalEnquiryVM";
import { useMemo } from "react";

export const useProposalEnquiryVM = () => {
    const [, setProposalEnquiryState] = useProposalEnquiryTracked();
    const proposalEnquiryVM = useMemo(() =>
        ProposalEnquiryVM({
            dispatch: [setProposalEnquiryState],
            companyRepo: CompanyRepoImpl(),
            standardTariffCodeRepo: StandardTariffCodeRepoImpl(),
            proposalEnquiryRepo: ProposalEnquiryRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
            tariffTypeRepo: TariffTypeRepoImpl(),
        }), [setProposalEnquiryState])

    return proposalEnquiryVM
}