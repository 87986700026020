import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ProposalEnquiryProvider } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { ProposalEnquiryHeader } from "presentation/view/section/ProposalEnquiry/ProposalEnquiryHeader";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const ProposalEnquiryHeaderContainer = () => {
    return <ANAInfoWrapper permission={Permission.LOGIN}>
        <ProposalEnquiryProvider>
            <GridStyles/>
            <ProposalEnquiryHeader />
        </ProposalEnquiryProvider>
    </ANAInfoWrapper>;
};