import { ApprovalProcessStatusPolicy } from "constants/proposal/ApprovalProcessStatusPolicy";
import { SearchModeEntity } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { ProposalEnquiryEntity } from "domain/entity/ProposalEnquiry/ProposalEnquiryEntity";
import { EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA, ProposalEnquirySearchCriteria } from "domain/entity/ProposalEnquiry/ProposalEnquirySearchCriteria";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { ProposalEnquiryRepository } from "domain/repository/Proposal/ProposalEnquiryRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffTypeRepository } from "domain/repository/TariffCode/TariffTypeRepo";
import { DomUtils } from "helpers/DomUtils";
import { customDispatchEvent, E_Custom_Dispatch_Event, E_Type_Of_Event } from "helpers/Events";
import _ from "lodash";
import { ActiveIndDroOpts } from "presentation/constant/DropDownOptions/Common/ActiveIndDroOpts";
import { ProposalEnquiryCriteriaCheckboxRelatedField } from "presentation/constant/ProposalEnquiry/ProposalEnquiryCriteriaCheckboxRelatedField";
import { DropdownProps } from "presentation/model/DropdownProps";
import { ProposalEnquiryModel } from "presentation/model/ProposalEnquiry/ProposalEnquiryModel";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface ProposalEnquiryVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ProposalEnquiryModel>> | ((value: SetStateAction<ProposalEnquiryModel>) => void)
    ],
    proposalEnquiryRepo: ProposalEnquiryRepository,
    companyRepo: CompanyRepository,
    masterDataRepo: MasterDataRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    tariffTypeRepo: TariffTypeRepository,

}

export const ProposalEnquiryVM = ({ dispatch, proposalEnquiryRepo, companyRepo, tariffTypeRepo, standardTariffCodeRepo, masterDataRepo }: ProposalEnquiryVMProps) => {

    const [proposalEnquiryDispatch] = dispatch;

    const onShowLoading = () => {
        proposalEnquiryDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        proposalEnquiryDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedRowsActivate = (rows: ProposalEnquiryEntity[]) => {
        let enableUpdate = false;
        const statuses: string[] = rows.map(proposal => proposal?.proposalStatus).filter((proposalStatus): proposalStatus is string => proposalStatus === ApprovalProcessStatusPolicy.STATUS_FINALIZED);
        if (rows.length > 0 && rows.length === statuses.length) {
            enableUpdate = true;
        }
        proposalEnquiryDispatch(prevState => {
            const objDispatch: { [x: string]: any } = { enableSelected: !_.isEmpty(rows), selectedRows: rows, enableActivate: enableUpdate, searchCriteria: prevState.searchCriteria };
            customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_ENABLE_ACTIVATE_BUTTON, objDispatch);
            return {
                ...prevState,
                selectedRows: rows
            }
        })
    }

    const setUserEmail = (userEmail: string) => {
        proposalEnquiryDispatch(prevState => ({
            ...prevState,
            userEmail: userEmail
        }))
    }

    const onDropdownChange = (e: any, fieldName: string) => {
        proposalEnquiryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e?.value ?? (_.isEmpty(e) ?
                    ((fieldName === "tariffCode") ? [] : '') : e),
            }
        }))
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        proposalEnquiryDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };

    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        proposalEnquiryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        proposalEnquiryDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: checked ? "Y" : "N",
            }
        }))
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return proposalEnquiryDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldKey]: val
                }
            }
        });
    }

    const loadDropdownOption = async () => {
        proposalEnquiryDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                activeIndDropdownOption: ActiveIndDroOpts().getDroOptsModel()
            }
        }))

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOption = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOption = _.orderBy(companyCodeDropdownOption, "dropdownLabel");

                proposalEnquiryDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        companyCodeDropdownOptions: [
                            ...companyCodeDropdownOption],

                    }
                }))
            }
        );

        await masterDataRepo.getMasterDataByKey(MasterDataType.TERMINAL).then(
            operatingTmls => {
                const operatingTmlDropdownOptions = operatingTmls?.map((operatingTml) => ({
                    dropdownLabel: operatingTml.code,
                    tagLabel: operatingTml.code,
                    value: operatingTml.code,
                })) ?? []

                proposalEnquiryDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operatingTmlDropdownOptions: operatingTmlDropdownOptions,
                    }
                }))
            }
        )

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {

                let newTariffs = _.orderBy(tariffs, ["tariffType", "tariffCode"]);
                let tariffTypeDropdownOptions: DropdownProps[] = [];
                let tariffCodeDropdownOptions: { [key: string]: DropdownProps[] } = {};
                let allTariffCodeeDropdownOptions: DropdownProps[] = [];

                newTariffs.forEach(tariff => {
                    const isTariffTypeExisted = tariffTypeDropdownOptions.find(t =>
                        t.value === tariff.tariffType);
                    if (!isTariffTypeExisted) {
                        tariffTypeDropdownOptions.push({
                            dropdownLabel: tariff.tariffType,
                            tagLabel: tariff.tariffType,
                            value: tariff.tariffType,
                        })
                    }

                    const isTariffCodeExisted = allTariffCodeeDropdownOptions.find(t =>
                        t.value === tariff.tariffCode);

                    if (!isTariffCodeExisted) {
                        allTariffCodeeDropdownOptions.push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })
                    }

                    if (tariff.tariffCode) {
                        if (!tariffCodeDropdownOptions[tariff.tariffType]) {
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })

                    }
                })

                proposalEnquiryDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        taxTariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                        taxTariffCodeDropdownOptions: tariffCodeDropdownOptions,
                        allTariffCodeeDropdownOptions: allTariffCodeeDropdownOptions,
                    }
                }))
            }
        )

    }

    // Open search criteria panel not preview panel
    const onEditSearchCriteria = () => {
        DomUtils.addWithPropSearchClass();
        proposalEnquiryDispatch(prevState => {
            if (prevState.searchCounter === 0) DomUtils.addOnlyPropSearchClass();
            else DomUtils.addWithPropSearchClass();
            return { ...prevState, isShowRightCriteriaPanel: true, isFilterSearchCriteria: true, isEditSearchCriteria: true, }
        });
    }


    const onSearchCriteriaResetClick = () => {
        proposalEnquiryDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA
            }
        })
    }



    const onHideRightCriteriaPanel = () => {
        proposalEnquiryDispatch(prevState => {
            if (prevState.isShowRightCriteriaPanel) {
                customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_HIDE_RIGHT_PANEL, {});
                DomUtils.removeWithPropSearchClass();
            } else {
                customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_HIDE_RIGHT_PANEL, { isDirectory: !prevState.isFilterSearchCriteria });
                DomUtils.addWithPropSearchClass();
            }

            return { ...prevState, isShowRightCriteriaPanel: !prevState.isShowRightCriteriaPanel, };
        });
    }


    // const onSearchClick = async() => {
    //     proposalEnquiryDispatch(prevState => {
    //         return {
    //             ...prevState,
    //             isShowCriteriaPanel: !prevState.isShowCriteriaPanel
    //         }
    //     });       
    // }
    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
        let val: any = e.target.value.toString();

        proposalEnquiryDispatch(prevState => {
            //if(fieldName === 'previewRefNo' || fieldName=== 'taxPaymentTerm'){
            const reg = /^\d*$/;
            if (!reg.test(val)) {
                if (fieldName === 'previewRefNo') {
                    val = prevState.searchCriteria.previewRefNo;
                } else if (fieldName === 'taxPaymentTerm') {
                    val = prevState.searchCriteria.taxPaymentTerm;
                }
            }
            /*}else*/
            if (fieldName === 'invNo' || fieldName === 'oriDocNo') {
                val = val.toUpperCase().replace(/\s+/g, '');
            }

            return ({
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: val,
                }
            })
        })
    };

    const onActivate = async (selectedRows: ProposalEnquiryEntity[]) => {
        return await proposalEnquiryRepo.activateTariffProposals(selectedRows).then((data) => {
            return data;
        }).catch((error) => {
            return "error";
        });
    }

    const onDeactivate = async (selectedRows: ProposalEnquiryEntity[]) => {
        return await proposalEnquiryRepo.deactivateTariffProposals(selectedRows).then((data) => {
            return data;
        }).catch((error) => {
            return "error";
        });
    }

    const validateSearchCriteria = (searchCriteria: ProposalEnquirySearchCriteria) => {
        if (searchCriteria) {

            for (const key in searchCriteria) {
                if (searchCriteria.hasOwnProperty(key)) {
                    const value = searchCriteria[key];
                    if (value !== null
                        && value !== ""
                        && !(Array.isArray(value) && value.length === 0)
                        && !(typeof value === "boolean" && value === false)) {
                        return false;
                    }
                }
            }
            return true;
        }
        return true;
    }

    const onSearch = async (counter?: number) => {
        DomUtils.addWithPropSearchClass();

        proposalEnquiryDispatch(prevState => {

            const tempCounter = counter ?? prevState.searchCounter;

            const obj: { [x: string]: boolean } = {};
            const objDispatch: { [x: string]: boolean } = { isEdit: true };
            if (tempCounter === 0) {
                obj.isShowRightCriteriaPanel = true;
                objDispatch.isEdit = true;
                obj.isEditSearchCriteria = false;
            } else {
                obj.isEditSearchCriteria = !prevState.isEditSearchCriteria;
            }

            customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_EDIT_CRITERIA_BACK, objDispatch);
            return { ...prevState, ...obj, searchCounter: tempCounter + 1 };
        });
    }

    const fetchTableData = async (searchCriteria: ProposalEnquirySearchCriteria, searchModeEntity: SearchModeEntity, setCriteria: boolean = false): Promise<any> => {
        DomUtils.addWithPropSearchClass();

        return await proposalEnquiryRepo.searchTariffProposal(searchCriteria).then((data) => {
            proposalEnquiryDispatch(prevState => {
                /*if (!setCriteria) {
                    return {
                        ...prevState,
                        proposals: data,
                    }
                } else {
                    return {
                        ...prevState,
                        proposals: data,
                        searchCriteria: searchCriteria,
                    }
                }*/
                return {
                    ...prevState,
                    proposals: data,
                    searchCriteria: searchCriteria,
                }
            })
            return data;
        }).catch((error) => {
            proposalEnquiryDispatch(prevState => {
                return {
                    ...prevState,
                    proposals: [],
                }
            })
            return "error";
        });
    }


    const openProposalDetail = (proposal: ProposalEnquiryEntity) => {
        const objDispatch: { [x: string]: boolean } = { isFullScreen: true };
        customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_DETAIL_SHOW, objDispatch);
        proposalEnquiryDispatch(prevState => ({
            ...prevState,
            isShowProposalDetail: true,
            currentProposal: proposal
        }))
    }

    const closeProposalDetail = () => {
        customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_DETAIL_HIDE, {});
        proposalEnquiryDispatch(prevState => ({
            ...prevState,
            isShowProposalDetail: false,
        }))
    }


    const onRemoveSearchCriteria = (fieldName: string, isSearch: boolean = true) => {
        //By default, we use disabled field name to retrieve default value from EMPTY_INVENTORY_SEARCH_CRITERIA,
        //If related field found, we use related field to retrieve default value.
        let resetSearchCriteriaPerField = { [fieldName]: EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA[fieldName] }
        if (ProposalEnquiryCriteriaCheckboxRelatedField[fieldName]) {
            resetSearchCriteriaPerField = ProposalEnquiryCriteriaCheckboxRelatedField[fieldName].reduce((objPerField, fieldNamePerField) => {
                return {
                    ...objPerField,
                    [fieldNamePerField]: EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA[fieldNamePerField],
                }
            }, {})
        }

        proposalEnquiryDispatch(prevState => {
            return {
                ...prevState,
                searchCounter: isSearch ? prevState.searchCounter + 1 : prevState.searchCounter,
                searchCriteria: { ...prevState.searchCriteria, ...resetSearchCriteriaPerField },
            }
        })
    }

    // Open directory criteria panel not search criteria preview panel
    const onSwitchSearchCriteriaMode = () => {
        DomUtils.addWithPropSearchClass();
        proposalEnquiryDispatch(prevState => {
            if (prevState.searchCounter === 0) DomUtils.addOnlyPropSearchClass();
            else DomUtils.addWithPropSearchClass();

            return {
                ...prevState, isShowRightCriteriaPanel: true, isFilterSearchCriteria: false, isEditSearchCriteria: false,
            };
        });
    }


    const onRemoveAllSearchCriteria = () => {
        DomUtils.addOnlyPropSearchClass();
        proposalEnquiryDispatch(prevState => {
            prevState.currentTableRef?.current?.api.setServerSideDatasource({
                getRows: (params: any) => {
                    params.success({
                        rowData: [],
                        rowCount: 0
                    })
                },
            })
            customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_EDIT_CRITERIA_BACK, { isEdit: false, selectedRows: [], enableActive: false });
            return {
                ...prevState,
                searchCounter: 0,
                searchCriteria: EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA,
            }
        })
    }

    return {
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onRemoveSearchCriteria: onRemoveSearchCriteria,
        onRemoveAllSearchCriteria: onRemoveAllSearchCriteria,
        onSwitchSearchCriteriaMode: onSwitchSearchCriteriaMode,
        onActivate: onActivate,
        onDeactivate: onDeactivate,
        onSearch: onSearch,
        setUserEmail: setUserEmail,
        onDropdownChange: onDropdownChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onDateRangeChange: onDateRangeChange,
        onCheckboxChange: onCheckboxChange,
        onFieldChange: onFieldChange,
        loadDropdownOption: loadDropdownOption,
        onEditSearchCriteria: onEditSearchCriteria,
        onSearchCriteriaResetClick: onSearchCriteriaResetClick,
        updateSelectedRowsActivate: updateSelectedRowsActivate,
        onHideRightCriteriaPanel: onHideRightCriteriaPanel,
        onInputTextChange: onInputTextChange,
        openProposalDetail: openProposalDetail,
        closeProposalDetail: closeProposalDetail,
        validateSearchCriteria: validateSearchCriteria,
        fetchTableData: fetchTableData,
    }

}