import { EMPTY_SEARCH_MODE_ENTITY } from "domain/entity/ChargeDataEnquiry/SearchModeEntity";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent, customEventListener } from "helpers/Events";
import { AclType } from "presentation/constant/ANAInfo/NbisivPermission";
import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { useProposalEnquiryVM } from "presentation/hook/ProposalEnquiry/useProposalEnquiryVM";
import { EMPTY_PROPOSAL_ENQUIRY_MODEL, ProposalEnquiryModel } from "presentation/model/ProposalEnquiry/ProposalEnquiryModel";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useProposalEnquiryTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import { isPermissionExist } from "presentation/utils/permissionUtils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HPHButton, IconButton } from "veronica-ui-component/dist/component/core";

export const ProposalEnquiryHeader: React.FC = () => {
    const [title, setTitle] = useState('PROPOSAL SEARCH');
    const [isList, setIsList] = useState(true);
    const [, setIsEditCriteria] = useState(false);

    const [isEdit,] = useState(false);
    // const [isShowLessDetail, setIsShowLessDetail] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [proposalEnquiryModel, setProposalEnquiryModel] = useState<ProposalEnquiryModel>(EMPTY_PROPOSAL_ENQUIRY_MODEL);
    const [isShowLegend] = useState<boolean>(false);
    const [enableActivate, setEnableActivate] = useState(false);

    const proposalEnquiryVM = useProposalEnquiryVM();

    const [proposalEnquiryState] = useProposalEnquiryTracked();

    const [anainfoState] = useANAInfoTracked();
    const isActivateRead = isPermissionExist(Permission.PROPOSAL_ENQUIRY_ACTIVATE, AclType.READ, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isActivateUpdate = isPermissionExist(Permission.PROPOSAL_ENQUIRY_ACTIVATE, AclType.UPDATE, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isDeactivateRead = isPermissionExist(Permission.PROPOSAL_ENQUIRY_DEACTIVATE, AclType.READ, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);
    const isDeactivateUpdate = isPermissionExist(Permission.PROPOSAL_ENQUIRY_DEACTIVATE, AclType.UPDATE, anainfoState.defaultOperatingCompany, anainfoState.allNbisivPermission);

    useEffect(() => {
        setProposalEnquiryModel(proposalEnquiryState)
    }, [proposalEnquiryState])

    const handleFullScreen = useCallback(() => {
        customDispatchEvent(E_Type_Of_Event.MODAL_EVENT, E_Custom_Dispatch_Event.MODAL_FULL_SCREEN_MODE, { isFullScreen: !isFullScreen, className: 'proposal-search-modal' });
        setIsFullScreen(!isFullScreen);
        setProposalEnquiryModel({
            ...proposalEnquiryModel,
            isShowLegendButton: !proposalEnquiryModel.isShowLegendButton
        });
    }, [isFullScreen, proposalEnquiryModel]);

    const handleClose = useCallback(() => {
        localStorage.removeItem(E_Custom_Dispatch_Event.REDIRECT_CHARGE_ITEM_FROM_DELIVERY_SUMMARY);
        customDispatchEvent(E_Type_Of_Event.MODAL_EVENT, E_Custom_Dispatch_Event.MODAL_CLOSE, { showProposalSearch: false });
    }, []);

    // Back button done
    const handleBackClick = useCallback(() => {
        customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_MODAL_BACK_TO_LIST, {});
    }, []);

    const handleDetailClose = useCallback(() => {
        if (!isEdit) customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_MODAL_BACK_TO_LIST, {});
    }, [isEdit]);

    const handleActivate = useCallback(() => {
        let objDispatch: { [x: string]: boolean } = { isLoading: true };
        customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_REFRESH_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_UPDATE, objDispatch);
        proposalEnquiryVM.onActivate(proposalEnquiryModel.selectedRows).then((data) => {
            if (typeof data !== 'string' && proposalEnquiryModel.searchCriteria) {
                proposalEnquiryVM.onSearch();
                proposalEnquiryVM.fetchTableData(proposalEnquiryModel.searchCriteria, EMPTY_SEARCH_MODE_ENTITY).then((data) => {
                    if (data && typeof data !== 'string') {
                        objDispatch = { isLoading: false, tableList: data };
                        customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_REFRESH_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_UPDATE, objDispatch);
                    }
                });
            }
        });
    }, [proposalEnquiryModel.selectedRows, proposalEnquiryVM, proposalEnquiryModel.searchCriteria]);

    const handleDeactivate = useCallback(() => {
        // customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_ENABLE_DEACTIVATE, {});
        let objDispatch: { [x: string]: boolean } = { isLoading: true };
        customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_REFRESH_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_UPDATE, objDispatch);
        proposalEnquiryVM.onDeactivate(proposalEnquiryModel.selectedRows).then((data) => {
            if (typeof data !== 'string' && proposalEnquiryModel.searchCriteria) {
                proposalEnquiryVM.onSearch();
                proposalEnquiryVM.fetchTableData(proposalEnquiryModel.searchCriteria, EMPTY_SEARCH_MODE_ENTITY).then((data) => {
                    if (data && typeof data !== 'string') {
                        objDispatch = { isLoading: false, tableList: data };
                        customDispatchEvent(E_Type_Of_Event.PROPOSAL_ENQUIRY_REFRESH_EVENT, E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_UPDATE, objDispatch);
                    }
                });
            }
        });
    }, [proposalEnquiryModel.selectedRows, proposalEnquiryVM, proposalEnquiryModel.searchCriteria]);

    const onProposalEnquiryChange = (e: any) => {
        switch (e.detail.action) {
            case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_EDIT_CRITERIA_BACK:
                setIsEditCriteria(e.detail.data.isEdit);
                setEnableActivate(e.detail.data.enableActivate);
                setProposalEnquiryModel(prev => ({ ...prev, selectedRows: e.detail.data.selectedRows }));
                break;
            case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_HIDE_RIGHT_PANEL:
                setIsEditCriteria(true);
                break;
            case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_DETAIL_SHOW:
                setTitle('PROPOSAL DETAILS');
                setIsList(false);
                e.detail.data.isFullScreen && handleFullScreen();
                break;
            case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_DETAIL_HIDE:
                setTitle('Proposal List');
                setIsList(true);
                break;
            case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_ENABLE_ACTIVATE_BUTTON:
                setEnableActivate(e.detail.data.enableActivate)
                setProposalEnquiryModel(prev => ({ ...prev, selectedRows: e.detail.data.selectedRows, searchCriteria: e.detail.data.searchCriteria }));
                break;
        }
    }

    // const overFlowMenu = useMemo(() => <OverflowMenuButton icon='Icon-bookmark' navigationList={preferenceMenu} />, [preferenceMenu]);    

    useEffect(() => {
        customEventListener(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, onProposalEnquiryChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function addLegendToParent(pDivId: string, cDivId: string, headerBox: string) {
        const timer = setInterval(() => {
            const pDiv = document.querySelector(pDivId);
            const cDiv = document.querySelector(cDivId);
            const box = document.querySelector(headerBox);
            const cls: string = cDiv?.getAttribute('class')?.toString() || '';

            if (cls.indexOf('added') >= 0) clearInterval(timer);

            if (pDiv && cDiv && box && cls.indexOf('added') < 0) {
                const pos = pDiv?.getBoundingClientRect();
                const boxPos = box?.getBoundingClientRect();
                cDiv.setAttribute('class', `${cls} added`);
                cDiv.setAttribute('style', `left:${pos && (pos.left - (boxPos?.width ?? 0) * 0.38)}px;top:${boxPos && (boxPos.top + 10)}px;`);
            }
        }, 200);
    }

    useEffect(() => {
        addLegendToParent('#Icon-help-charge-detail-table', '#charge-detail-legend', '#charge-data-search-header-box');
    }, [isShowLegend, isFullScreen, proposalEnquiryModel.isShowLegendButton]);

    const getListHeader: JSX.Element = useMemo(() => {
        return <>
            <div id="charge-data-search-header-box" className="md-header-box">
                <span className="md-header-title" style={{ flexGrow: 1 }}>{title}</span>
                <div className="md-header-option" style={{ flexGrow: 0, justifyContent: "space-between", flexShrink: 0 }}>
                    {enableActivate && isActivateRead && isActivateUpdate && <HPHButton label={'Activate'} size={'Small'} theme={'Primary'} onClick={handleActivate} />}
                    {enableActivate && isDeactivateRead && isDeactivateUpdate && <HPHButton label={'Deactivate'} size={'Small'} theme={'Primary'} onClick={handleDeactivate} />}

                </div>
            </div>
            <div className={`md-header-action`} style={{ width: '75px' }}>
                <IconButton fileName={isFullScreen ? 'Icon-minimise' : 'Icon-enlarge'} tooltipDisable={false} toolTipText={!isFullScreen ? 'Maximise' : 'Restore'} size="medium" onClick={handleFullScreen} />
                <IconButton fileName="Icon-cross" toolTipText="Close" tooltipDisable={false} size="medium" onClick={handleClose} />
            </div>
        </>
    }, [title, enableActivate, isActivateRead, isActivateUpdate, handleActivate, isDeactivateRead, isDeactivateUpdate, handleDeactivate, isFullScreen, handleFullScreen, handleClose]);

    const getDetailHeader: JSX.Element = useMemo(() => {
        return <>
            <div className="md-header-box">
                <span className="md-header-title" style={{ flexGrow: 1 }}>
                    {!isEdit && <IconButton fileName='Icon-arrow-left' toolTipText='Back' toolTipPlacement="right" onClick={handleBackClick} />}
                    <span>{title}</span>
                </span>
            </div>
            <div className={`md-header-action`} style={{ width: (isEdit ? '106px' : '75px') }}>
                <IconButton fileName={isFullScreen ? 'Icon-minimise' : 'Icon-enlarge'} tooltipDisable={false} toolTipText={!isFullScreen ? 'Maximise' : 'Restore'} size="medium" onClick={handleFullScreen} />
                <IconButton fileName='Icon-cross' size="medium" onClick={handleDetailClose} toolTipText='Close' />
            </div>
        </>
    }, [title, isFullScreen, isEdit, handleBackClick, handleFullScreen, handleDetailClose]);

    if (isList) return getListHeader;

    return getDetailHeader;
}