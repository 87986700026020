import { EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA } from "domain/entity/ProposalEnquiry/ProposalEnquirySearchCriteria";
import _ from "lodash";
import { approvalProcessStatusDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { ProposalEnquiryConstant } from "presentation/constant/ProposalEnquiry/ProposalEnquiryConstant";
import { useProposalEnquiryVM } from "presentation/hook/ProposalEnquiry/useProposalEnquiryVM";
import { useProposalEnquiryTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { useMemo } from "react";
import {
    CommonField,
    FieldType,
    IFieldValue,
    InputDropdown,
    InputField
} from "veronica-ui-component/dist/component/core";


export const ProposalEnquiryCriteriaEditPanel = () => {
    //const CHARGE_CONSTANT = ChargeConstant.Charge;
    const PROPOSAL_ENQ_HDR_CONST = ProposalEnquiryConstant.Header;
    const [proposalEnquiryState] = useProposalEnquiryTracked();
    const searchCriteria = proposalEnquiryState.searchCriteria;
    const proposalEnquiryVM = useProposalEnquiryVM();
    // const enabledCriteria = useMemo(() => CriteriaDirectoryVoyageCheckboxOption.find(item => proposalEnquiryState.enabledSearchCriteria[item.key]), [proposalEnquiryState.enabledSearchCriteria]);

    const { dynamicOptions } = proposalEnquiryState;

    const memoCompanyCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={PROPOSAL_ENQ_HDR_CONST.COMPANY_CODE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.companyCode?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => {
                    proposalEnquiryVM.onMultipleDropdownChange(e, 'companyCode')
                }}
                options={proposalEnquiryState.dynamicOptions.companyCodeDropdownOptions} />
        </div>
        , [PROPOSAL_ENQ_HDR_CONST.COMPANY_CODE, proposalEnquiryState.dynamicOptions.companyCodeDropdownOptions, proposalEnquiryVM, searchCriteria.companyCode])

    const memoProposalNo = useMemo(() =>
        /*<div className='im-flex-row-item'>
            <InputDropdown
                label={PROPOSAL_ENQ_HDR_CONST.PROPOSAL_NO}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria?.proposalNo??''}
                onChange={(e) => {
                    proposalEnquiryVM.onMultipleDropdownChange(e, 'proposalNo')
                }}
                />
        </div>*/
        <div className='im-flex-row-item'>
            <InputField
                width='150px'
                maxLength={15}
                label={PROPOSAL_ENQ_HDR_CONST.PROPOSAL_NO}
                type="text"
                value={searchCriteria?.proposalNo ?? ''}
                onChange={(e) => proposalEnquiryVM.onInputTextChange(e, 'proposalNo')} />
        </div>
        , [PROPOSAL_ENQ_HDR_CONST.PROPOSAL_NO, proposalEnquiryVM, searchCriteria?.proposalNo])

    const memoOperatingTml = useMemo(() =>
        <div className='im-flex-row-item' >
            <InputDropdown
                label={PROPOSAL_ENQ_HDR_CONST.OPERATING_TML}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.operatingTml?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => {
                    proposalEnquiryVM.onMultipleDropdownChange(e, 'operatingTml')
                }}
                options={proposalEnquiryState.dynamicOptions.operatingTmlDropdownOptions} />
        </div>
        , [PROPOSAL_ENQ_HDR_CONST.OPERATING_TML, proposalEnquiryState.dynamicOptions.operatingTmlDropdownOptions, proposalEnquiryVM, searchCriteria.operatingTml])

    const memoEffectiveDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={PROPOSAL_ENQ_HDR_CONST.EFFECTIVE_DATE_RANGE}
                width="330px"
                fieldNames={{ startField: "effectiveFromDate", endField: "effectiveToDate" }}
                dateRange={{ startDate: searchCriteria.effectiveFromDate, endDate: searchCriteria.effectiveToDate }}
                onDatesChange={proposalEnquiryVM.onDateRangeChange}
            />
        </div>
        , [PROPOSAL_ENQ_HDR_CONST.EFFECTIVE_DATE_RANGE, searchCriteria.effectiveFromDate, searchCriteria.effectiveToDate, proposalEnquiryVM.onDateRangeChange])

    const memoTariffCodeOptions = useMemo(() => {
        const groupOfTariffCodeOptions = searchCriteria.tariffType?.map(tariffType => proposalEnquiryState.dynamicOptions.tariffCodeDropdownOptions[tariffType]);
        const commonTariffCodeOptions = _.orderBy(_.uniqBy(_.flatMap(_.concat(groupOfTariffCodeOptions ?? [])), 'value'), 'dropdownLabel');
        return commonTariffCodeOptions;
    }, [searchCriteria.tariffType, proposalEnquiryState.dynamicOptions.tariffCodeDropdownOptions])

    const memoTariffType = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={PROPOSAL_ENQ_HDR_CONST.TAR_TYPE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.tariffType?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => {
                    proposalEnquiryVM.onMultipleDropdownChange(e, 'tariffType')
                    proposalEnquiryVM.onDropdownChange(EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA.tariffCode, 'tariffCode')
                }}
                options={proposalEnquiryState.dynamicOptions.tariffTypeDropdownOptions} />
        </div>
        , [PROPOSAL_ENQ_HDR_CONST.TAR_TYPE, searchCriteria.tariffType, proposalEnquiryState.dynamicOptions.tariffTypeDropdownOptions, proposalEnquiryVM])

    const memoTariffCode = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={PROPOSAL_ENQ_HDR_CONST.TAR_CODE}
                width='150px'
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.tariffCode?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => proposalEnquiryVM.onMultipleDropdownChange(e, 'tariffCode')
                }
                options={memoTariffCodeOptions} />
        </div>
        , [PROPOSAL_ENQ_HDR_CONST.TAR_CODE, searchCriteria.tariffCode, memoTariffCodeOptions, proposalEnquiryVM])


    const memoStatus = useMemo(() =>
        <div className='flex-row-item'>
            <InputDropdown
                label={PROPOSAL_ENQ_HDR_CONST.STATUS}
                width="330px"
                inputType="freeText"
                mode={'multiple'}
                sort={false}
                value={searchCriteria.proposalStatus?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => proposalEnquiryVM.onMultipleDropdownChange(e, 'proposalStatus')}
                options={approvalProcessStatusDropdownOption} />
        </div>
        , [PROPOSAL_ENQ_HDR_CONST.STATUS, searchCriteria.proposalStatus, proposalEnquiryVM])

    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "150px" }}>
            <CommonField
                isReadOnly={false}
                isShowOptional={true}
                isMultipleDropDown={false}
                disableInput={true}
                fieldValue={searchCriteria?.activeInd ?? ''}
                fieldLabel={PROPOSAL_ENQ_HDR_CONST.ACTIVE_IND}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'activeInd'}
                options={dynamicOptions.activeIndDropdownOption}
                maxLength={40}
                sort={false}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    proposalEnquiryVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [PROPOSAL_ENQ_HDR_CONST.ACTIVE_IND, proposalEnquiryVM, searchCriteria?.activeInd, dynamicOptions.activeIndDropdownOption])


    return (
        <div className='im-charge-data-search-criteria-group'>
            <CriteriaItemContainer>
                {memoCompanyCode}
                {memoProposalNo}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {memoOperatingTml}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                <>{memoEffectiveDateFromTo}</>
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {memoTariffType}
                {memoTariffCode}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {memoStatus}
            </CriteriaItemContainer>
            <CriteriaItemContainer>
                {memoActiveInd}
            </CriteriaItemContainer>
        </div>
    );
};
